const CompanyInfo =  {

    name: "New Atoll Tablewares",
    title: "Welcome to New Atoll Tablewares",
    logo: "/assets/images/atol-logo.png"


};

export default CompanyInfo;
