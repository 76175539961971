import React from 'react';
import Assets from '../constants/Assets'
import Header from './Header';
import '../assets/css/ui.css';
import ProjectRoot from './ProductRoot';
import Footer from './Footer';


function getData(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
class Products extends React.Component{

    state = {
        "category" : Assets.tablewares,
        "data":[]
    };

    componentDidMount = (props) => {
        var category = new URLSearchParams(this.props.location.search).get("category");
        if(category == undefined){
            category = Assets.tablewares;
        }
        switch(category){
            case Assets.tablewares: this.setState({category : Assets.tablewares,
                data : getData(require.context('../assets/images/tablewares/', false, /\.(png|jpe?g|svg)$/))}); break;
            case Assets.platters: this.getPlatters(); break;
            case Assets.barwares : this.getBarwares(); break;
            case Assets.copper_antique_finish : this.getCopperAntiqueFinish(); break;
            case Assets.thali_set : this.getThaliSet(); break;
        }

    }

    getTablewares = () => {
        if(this.state.category === Assets.tablewares){
            return;
        }
        document.getElementById(this.state.category).classList.remove('side-element-active');
        document.getElementById(Assets.tablewares).classList.add('side-element-active');
        this.setState({category : Assets.tablewares,
        data : getData(require.context('../assets/images/tablewares/', false, /\.(png|jpe?g|svg)$/))});
    }

    getPlatters = () => {
        if(this.state.category === Assets.platters){
            return;
        }
        document.getElementById(this.state.category).classList.remove('side-element-active');
        document.getElementById(Assets.platters).classList.add('side-element-active');
        this.setState({category : Assets.platters,
        data : getData(require.context('../assets/images/platters/', false, /\.(png|jpe?g|svg)$/))});
    }

    getBarwares = () => {
        if(this.state.category === Assets.barwares){
            return;
        }
        document.getElementById(this.state.category).classList.remove('side-element-active');
        document.getElementById(Assets.barwares).classList.add('side-element-active');
        this.setState({category : Assets.barwares,
        data : getData(require.context('../assets/images/barwares/', false, /\.(png|jpe?g|svg)$/))});
    }

    getCopperAntiqueFinish = () => {
        if(this.state.category === Assets.copper_antique_finish){
            return;
        }
        document.getElementById(this.state.category).classList.remove('side-element-active');
        document.getElementById(Assets.copper_antique_finish).classList.add('side-element-active');
        this.setState({category : Assets.copper_antique_finish,
        data : getData(require.context('../assets/images/copper_antique_finish/', false, /\.(png|jpe?g|svg|webp)$/))});
    }

    getThaliSet = () => {
        if(this.state.category === Assets.thali_set){
            return;
        }
        document.getElementById(this.state.category).classList.remove('side-element-active');
        document.getElementById(Assets.thali_set).classList.add('side-element-active');
        this.setState({category : Assets.thali_set,
        data : getData(require.context('../assets/images/thali_set/', false, /\.(png|jpe?g|svg|webp)$/))});
    }

    render(){
        return (
            <div className="Products">
                <Header message="menuProducts"/>

                <section className="section-content padding-y">
                <div className="container">
                <div className="row">
                    <aside className="col-md-3">

                <div className="card">
                    <article className="filter-group">
                        <header className="card-header">
                            <a href="#" data-toggle="collapse" data-target="#collapse_1" aria-expanded="true" className="">
                                <i className="icon-control fa fa-chevron-down"></i>
                                <h6 className="title">Category</h6>
                            </a>
                        </header>
                        <div className="filter-content collapse show" id="collapse_1">
                            <div className="card-body">
                                <ul className="list-menu">
                                <li><a className="side-element-active" id={Assets.tablewares} href={"/products?category="+Assets.tablewares}>Tablewares</a></li>
                                <li><a id={Assets.platters} onClick={this.getPlatters} href={"/products?category="+Assets.platters}>Platters</a></li>
                                <li><a id={Assets.barwares} onClick={this.getBarwares} href={"/products?category="+Assets.barwares}>Barwares</a></li>
                                <li><a id={Assets.copper_antique_finish} onClick={this.getCopperAntiqueFinish} href={"/products?category="+Assets.copper_antique_finish}>Copper Antique Finish</a></li>
                                <li><a id={Assets.thali_set} onClick={this.getThaliSet} href={"/products?category="+Assets.thali_set}>Thali Set</a></li>
                                </ul>
                            </div>
                        </div>
                    </article>
                    {/* <article className="filter-group">
                        <header className="card-header">
                            <a href="#" data-toggle="collapse" data-target="#collapse_2" aria-expanded="true" className="">
                                <i className="icon-control fa fa-chevron-down"></i>
                                <h6 className="title">Brands </h6>
                            </a>
                        </header>
                        <div className="filter-content collapse show" id="collapse_2">
                            <div className="card-body">
                                <label className="custom-control custom-checkbox">
                                  <input type="checkbox" className="custom-control-input" />
                                  <div className="custom-control-label">Mercedes
                                      <b className="badge badge-pill badge-light float-right">120</b>  </div>
                                </label>
                                <label className="custom-control custom-checkbox">
                                  <input type="checkbox" className="custom-control-input" />
                                  <div className="custom-control-label">Toyota
                                      <b className="badge badge-pill badge-light float-right">15</b>  </div>
                                </label>
                                <label className="custom-control custom-checkbox">
                                  <input type="checkbox" className="custom-control-input" />
                                  <div className="custom-control-label">Mitsubishi
                                      <b className="badge badge-pill badge-light float-right">35</b> </div>
                                </label>
                                <label className="custom-control custom-checkbox">
                                  <input type="checkbox" className="custom-control-input" />
                                  <div className="custom-control-label">Nissan
                                      <b className="badge badge-pill badge-light float-right">89</b> </div>
                                </label>
                                <label className="custom-control custom-checkbox">
                                  <input type="checkbox" className="custom-control-input" />
                                  <div className="custom-control-label">Honda
                                      <b className="badge badge-pill badge-light float-right">30</b>  </div>
                                </label>
                    </div>
                        </div>
                    </article>
                    <article className="filter-group">
                        <header className="card-header">
                            <a href="#" data-toggle="collapse" data-target="#collapse_3" aria-expanded="true" className="">
                                <i className="icon-control fa fa-chevron-down"></i>
                                <h6 className="title">Price range </h6>
                            </a>
                        </header>
                        <div className="filter-content collapse show" id="collapse_3">
                            <div className="card-body">
                                <input type="range" className="custom-range" min="0" max="100" name="" />
                                <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label>Min</label>
                                  <input className="form-control" placeholder="$0" type="number" />
                                </div>
                                <div className="form-group text-right col-md-6">
                                  <label>Max</label>
                                  <input className="form-control" placeholder="$1,0000" type="number" />
                                </div>
                                </div>
                                <button className="btn btn-block btn-primary">Apply</button>
                            </div>
                        </div>
                    </article>
                    <article className="filter-group">
                        <header className="card-header">
                            <a href="#" data-toggle="collapse" data-target="#collapse_4" aria-expanded="true" className="">
                                <i className="icon-control fa fa-chevron-down"></i>
                                <h6 className="title">Sizes </h6>
                            </a>
                        </header>
                        <div className="filter-content collapse show" id="collapse_4">
                            <div className="card-body">
                              <label className="checkbox-btn">
                                <input type="checkbox" />
                                <span className="btn btn-light"> XS </span>
                              </label>
                              <label className="checkbox-btn">
                                <input type="checkbox" />
                                <span className="btn btn-light"> SM </span>
                              </label>
                              <label className="checkbox-btn">
                                <input type="checkbox" />
                                <span className="btn btn-light"> LG </span>
                              </label>
                              <label className="checkbox-btn">
                                <input type="checkbox" />
                                <span className="btn btn-light"> XXL </span>
                              </label>
                        </div>
                        </div>
                    </article>
                    <article className="filter-group">
                        <header className="card-header">
                            <a href="#" data-toggle="collapse" data-target="#collapse_5" aria-expanded="false" className="">
                                <i className="icon-control fa fa-chevron-down"></i>
                                <h6 className="title">More filter </h6>
                            </a>
                        </header>
                        <div className="filter-content collapse in" id="collapse_5">
                            <div className="card-body">
                                <label className="custom-control custom-radio">
                                  <input type="radio" name="myfilter_radio" checked="" className="custom-control-input" />
                                  <div className="custom-control-label">Any condition</div>
                                </label>
                                <label className="custom-control custom-radio">
                                  <input type="radio" name="myfilter_radio" className="custom-control-input" />
                                  <div className="custom-control-label">Brand new </div>
                                </label>
                                <label className="custom-control custom-radio">
                                  <input type="radio" name="myfilter_radio" className="custom-control-input" />
                                  <div className="custom-control-label">Used items</div>
                                </label>
                                <label className="custom-control custom-radio">
                                  <input type="radio" name="myfilter_radio" className="custom-control-input" />
                                  <div className="custom-control-label">Very old</div>
                                </label>
                            </div>
                        </div>
                    </article>  */}
                </div>
                    </aside>
                    <main className="col-md-9">

                <div id="products-root" className="row">

                    <ProjectRoot data={this.state.data}/>



                    {/* <div className="col-md-4">
                        <figure className="card card-product-grid">
                            <div className="img-wrap">
                                <span className="badge badge-danger"> NEW </span>
                                <img src="assets/images/1.jpg" />
                                <a className="btn-overlay" href="#"><i className="fa fa-search-plus"></i> Quick view</a>
                            </div>
                            <figcaption className="info-wrap">
                                <div className="fix-height">
                                    <a href="#" className="title">Great item name goes here</a>
                                    <div className="price-wrap mt-2">
                                    </div>
                                </div>
                                <a href="#" className="btn btn-block btn-primary">Add to cart </a>
                            </figcaption>
                        </figure>
                    </div>  */}
                    {/* <div className="col-md-4">
                        <figure className="card card-product-grid">
                            <div className="img-wrap">
                                <img src="assets/images/2.jpg" />
                                <a className="btn-overlay" href="#"><i className="fa fa-search-plus"></i> Quick view</a>
                            </div>
                            <figcaption className="info-wrap">
                                <div className="fix-height">
                                    <a href="#" className="title">Product name goes here just for demo item</a>
                                    <div className="price-wrap mt-2">
                                        <span className="price">$1280</span>
                                    </div>
                                </div>
                                <a href="#" className="btn btn-block btn-primary">Add to cart </a>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-md-4">
                        <figure className="card card-product-grid">
                            <div className="img-wrap">
                                <img src="assets/images/3.jpg" />
                                <a className="btn-overlay" href="#"><i className="fa fa-search-plus"></i> Quick view</a>
                            </div>
                            <figcaption className="info-wrap">
                                <div className="fix-height">
                                    <a href="#" className="title">Product name goes here just for demo item</a>
                                    <div className="price-wrap mt-2">
                                        <span className="price">$1280</span>
                                    </div>
                                </div>
                                <a href="#" className="btn btn-block btn-primary">Add to cart </a>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-md-4">
                        <figure className="card card-product-grid">
                            <div className="img-wrap">
                                <img src="assets/images/4.jpg" />
                                <a className="btn-overlay" href="#"><i className="fa fa-search-plus"></i> Quick view</a>
                            </div>
                            <figcaption className="info-wrap">
                                <div className="fix-height">
                                    <a href="#" className="title">Product name goes here just for demo item</a>
                                    <div className="price-wrap mt-2">
                                        <span className="price">$1280</span>
                                    </div>
                                </div>
                                <a href="#" className="btn btn-block btn-primary">Add to cart </a>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-md-4">
                        <figure className="card card-product-grid">
                            <div className="img-wrap">
                                <img src="assets/images/5.jpg" />
                                <a className="btn-overlay" href="#"><i className="fa fa-search-plus"></i> Quick view</a>
                            </div>
                            <figcaption className="info-wrap">
                                <div className="fix-height">
                                    <a href="#" className="title">Product name goes here just for demo item</a>
                                    <div className="price-wrap mt-2">
                                        <span className="price">$1280</span>
                                    </div>
                                </div>
                                <a href="#" className="btn btn-block btn-primary">Add to cart </a>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-md-4">
                        <figure className="card card-product-grid">
                            <div className="img-wrap">
                                <img src="assets/images/6.jpg" />
                                <a className="btn-overlay" href="#"><i className="fa fa-search-plus"></i> Quick view</a>
                            </div>
                            <figcaption className="info-wrap">
                                <div className="fix-height">
                                    <a href="#" className="title">Product name goes here just for demo item</a>
                                    <div className="price-wrap mt-2">
                                        <span className="price">$1280</span>
                                    </div>
                                </div>
                                <a href="#" className="btn btn-block btn-primary">Add to cart </a>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-md-4">
                        <figure className="card card-product-grid">
                            <div className="img-wrap">
                                <img src="assets/images/7.jpg" />
                                <a className="btn-overlay" href="#"><i className="fa fa-search-plus"></i> Quick view</a>
                            </div>
                            <figcaption className="info-wrap">
                                <div className="fix-height">
                                    <a href="#" className="title">Product name goes here just for demo item</a>
                                    <div className="price-wrap mt-2">
                                        <span className="price">$1280</span>
                                    </div>
                                </div>
                                <a href="#" className="btn btn-block btn-primary">Add to cart </a>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-md-4">
                        <figure className="card card-product-grid">
                            <div className="img-wrap">
                                <img src="assets/images/1.jpg" />
                                <a className="btn-overlay" href="#"><i className="fa fa-search-plus"></i> Quick view</a>
                            </div>
                            <figcaption className="info-wrap">
                                <div className="fix-height">
                                    <a href="#" className="title">Product name goes here just for demo item</a>
                                    <div className="price-wrap mt-2">
                                        <span className="price">$1280</span>
                                    </div>
                                </div>
                                <a href="#" className="btn btn-block btn-primary">Add to cart </a>
                            </figcaption>
                        </figure>
                    </div>  */}
                </div>
                {/* <nav className="mt-4" aria-label="Page navigation sample">
                  <ul className="pagination">
                    <li className="page-item disabled"><a className="page-link" href="#">Previous</a></li>
                    <li className="page-item active"><a className="page-link" href="#">1</a></li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                  </ul>
                </nav> */}
                    </main>
                </div>
                </div>
                </section>

                <Footer />


            </div>
          );
    }
}


export default Products;
