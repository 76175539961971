import React from 'react';
     
  
class Maps extends React.Component{

    state = {
        sw : document.body.clientWidth, 
        bp : 550,
        staticURL : "https://maps.google.com/maps/api/staticmap?center=55.7402023,12.5341835&zoom=13&markers=55.7402023,12.5341835&size=640x320&sensor=true",
        embed : '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d218.47602413261433!2d78.77955973918529!3d28.820503573993882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390afb35d30d80b5%3A0x7868cbc5d2981505!2sNew%20Atoll%20Tablewares!5e0!3m2!1sen!2sin!4v1644898307589!5m2!1sen!2sin" width="100%" height="100%" style="border:0;" allowfullscreen="false" loading="lazy"></iframe>'
    }

    buildMap = () =>{
        if(this.state.sw>this.state.bp) { //If Large Screen
            // $('.map-container')
            if(document.getElementsByClassName('map-container').length < 1) { //If map doesn't already exist
                this.buildEmbed();
            }
        } else {
            // $('.static-img')
            if(document.getElementsByClassName('static-img').length < 1) { //If static image doesn't exist
                this.buildStatic();
            }
        }
    };

    buildEmbed = () =>  { //Build iframe view
        var ele = document.createElement('div');
        ele.classList.add('map-container');
        ele.innerHTML = this.state.embed;
        document.getElementsByClassName('map')[0].prepend(ele);
    };

    buildStatic = () => { //Build static map
        var ele = document.createElement('div');
        ele.classList.add('map-container');
        ele.innerHTML = this.state.embed;
        var map = document.getElementsByClassName('map')[0];
        map.pre = ele;
        var mapLink = document.getElementsByClassName('map-link')[0];
        if(mapLink){
            var imgEle = document.createElement('img');
            imgEle.classList.add('static-img');
            imgEle.setAttribute('scr', this.state.staticURL);
            mapLink.setAttribute('href', imgEle);
            var aEle = document.createElement('a');
            aEle.setAttribute('href', mapLink);
            aEle.innerHTML = imgEle;
            map.prepend(aEle);
        }
    
    }

    componentDidMount = props => {
        this.buildMap();
    }

    render(){
        return (
            <div id="pattern" className="pattern">
                <div className="map">
                </div>
            </div>
        );
    }

}
export default Maps;