import React from 'react';


const ProjectRoot = props => {

    if(props.data != undefined){
        return (
            <>
                {
                    Object.entries(props.data).map(image => {
                        return (
                            <div key={image[0]} className="col-md-4">
                                <figure className="card card-product-grid">
                                    <div className="img-wrap">
                                        {/* <span className="badge badge-danger"> NEW </span> */}
                                        <img class="image-card-cover" src={image[1]} style={{pointerEvents: 'none' }}  />
                                        {/* <a className="btn-overlay" href="#"><i className="fa fa-search-plus"></i> Quick view</a> */}
                                    </div>
                                    <figcaption className="info-wrap">
                                        <div className="fix-height">
                                            {(image[0].substring(0, image[0].lastIndexOf('.')) || image[0]).replaceAll('_',' ').toUpperCase()}
                                            <div className="price-wrap mt-2">
                                            </div>
                                        </div>
                                        {/* <a href="#" className="btn btn-block btn-primary">Add to cart </a> */}
                                    </figcaption>
                                </figure>
                            </div>
                        )
                    })
                }
            </>
        );
    }
    return (<div></div>)

}
export default ProjectRoot;
