import React from 'react';
import Header from './Header';
import Footer from './Footer';
import tablewares from '../assets/images/tablewares/TEA_COFEE_SET_2CC_4CC_6CC.jpg';
import platters from '../assets/images/platters/SQUARE_PLATTER_6X6_7X7_8X8_9X9_10X10.jpg';
import barwares from '../assets/images/barwares/WINE_BUCKET.jpg';
import copperAntiqueFinish from '../assets/images/copper_antique_finish/DINNER_SET_56PCS.jpg';
import thaliSet from '../assets/images/thali_set/MAHARAJA_THALI_SET.jpg';
import Assets from '../constants/Assets';
import BestSellers from './BestSellers';


function getData(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

class Home extends React.Component {

  state = {
    "best_sellers":[]
  };

  componentDidMount = props =>{
    this.setState({best_sellers : getData(require.context('../assets/images/best_sellers', false, /\.(png|jpe?g|svg)$/))});
  }

  render() {

    return (

      <div className="MainDiv">

        <Header message="menuHome" />


        <div className="main_slider" style={{
          background: window.matchMedia("(min-width: 1460px)").matches ? 'url("assets/images/atol-main-slider-right.png")' :
          (window.matchMedia("(min-width:1200px)").matches && window.matchMedia("(max-width:1459px)").matches ? 'url("assets/images/atol-main-slider-2.png")' :
          window.matchMedia("(min-width:798px)").matches && window.matchMedia("(max-width:1199px)").matches ? 'url("assets/images/atol-main-slider-3.png")' : 'none')
          , display:'block', maxWidth: '100%', height: 'auto', backgroundPosition: 'right',
      }}>
          <div className="container fill_height">
            <div className="row align-items-center fill_height">
              <div className="col">
                <div className="main_slider_content">
                  <h6>Atoll India / Premium Collection</h6>
                  <h1>Get our Premiun Products</h1>
                  <div className="red_button shop_now_button"><a href="/products">view now</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-md-4 padding-top-30">
                <div className="banner_item align-items-center border-box" style={{backgroundImage: `url(${tablewares})`}}>
                  <div className="banner_category">
                    <a href={"/products?category="+Assets.tablewares}>Tablewares</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 padding-top-30">
                <div className="banner_item align-items-center border-box" style={{backgroundImage:`url(${platters})`}}>
                  <div className="banner_category">
                    <a href={"/products?category="+Assets.platters}>Platters</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 padding-top-30">
                <div className="banner_item align-items-center border-box" style={{backgroundImage:`url(${barwares})`}}>
                  <div className="banner_category">
                    <a href={"/products?category="+Assets.barwares}>Barwares</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row padding-top-30">
              <div className="col-md-4 padding-top-30">
                <div className="banner_item align-items-center border-box" style={{backgroundImage: `url(${copperAntiqueFinish})`, backgroundSize:'contain'}}>
                  <div className="banner_category">
                    <a href={"/products?category="+Assets.copper_antique_finish}>Copper Antique Finish</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 padding-top-30">
                <div className="banner_item align-items-center border-box" style={{backgroundImage:`url(${thaliSet})`, backgroundSize:'contain'}}>
                  <div className="banner_category">
                    <a href={"/products?category="+Assets.thali_set}>Thali Set</a>
                  </div>
                </div>
              </div>
              {/*
              <div className="col-md-4">
                <div className="banner_item align-items-center border-box" style={{backgroundImage:`url(${barwares})`}}>
                  <div className="banner_category">
                    <a href={"/products?category="+Assets.barwares}>Barwares</a>
                  </div>
                </div>
              </div>
              */}
            </div>
          </div>
        </div>

        {/* <div className="new_arrivals">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <div className="section_title new_arrivals_title">
                  <h2>Our Products</h2>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col text-center">
                <div className="new_arrivals_sorting">
                  <ul className="arrivals_grid_sorting clearfix button-group filters-button-group">
                    <li className="grid_sorting_button button d-flex flex-column justify-content-center align-items-center active is-checked" data-filter="*">all</li>
                    <li className="grid_sorting_button button d-flex flex-column justify-content-center align-items-center" data-filter=".women">women's</li>
                    <li className="grid_sorting_button button d-flex flex-column justify-content-center align-items-center" data-filter=".accessories">accessories</li>
                    <li className="grid_sorting_button button d-flex flex-column justify-content-center align-items-center" data-filter=".men">men's</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="product-grid" data-isotope='{ "itemSelector": ".product-item", "layoutMode": "fitRows" }'>

                  <div className="product-item men">
                    <div className="product discount product_filter">
                      <div className="product_image">
                        <img src="assets/images/product_1.png" alt="" />
                      </div>
                      <div className="favorite favorite_left"></div>
                      <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center"><span>-$20</span></div>
                      <div className="product_info">
                        <h6 className="product_name"><a href="#">Fujifilm X100T 16 MP Digital Camera (Silver)</a></h6>
                        <div className="product_price">$520.00<span>$590.00</span></div>
                      </div>
                    </div>
                    <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                  </div>

                  <div className="product-item women">
                    <div className="product product_filter">
                      <div className="product_image">
                        <img src="assets/images/product_2.png" alt="" />
                      </div>
                      <div className="favorite"></div>
                      <div className="product_bubble product_bubble_left product_bubble_green d-flex flex-column align-items-center"><span>new</span></div>
                      <div className="product_info">
                        <h6 className="product_name"><a href="#">Samsung CF591 Series Curved 27-Inch FHD Monitor</a></h6>
                        <div className="product_price">$610.00</div>
                      </div>
                    </div>
                    <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                  </div>

                  <div className="product-item women">
                    <div className="product product_filter">
                      <div className="product_image">
                        <img src="assets/images/product_3.png" alt="" />
                      </div>
                      <div className="favorite"></div>
                      <div className="product_info">
                        <h6 className="product_name"><a href="#">Blue Yeti USB Microphone Blackout Edition</a></h6>
                        <div className="product_price">$120.00</div>
                      </div>
                    </div>
                    <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                  </div>

                  <div className="product-item accessories">
                    <div className="product product_filter">
                      <div className="product_image">
                        <img src="assets/images/product_4.png" alt="" />
                      </div>
                      <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center"><span>sale</span></div>
                      <div className="favorite favorite_left"></div>
                      <div className="product_info">
                        <h6 className="product_name"><a href="#">DYMO LabelWriter 450 Turbo Thermal Label Printer</a></h6>
                        <div className="product_price">$410.00</div>
                      </div>
                    </div>
                    <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                  </div>

                  <div className="product-item women men">
                    <div className="product product_filter">
                      <div className="product_image">
                        <img src="assets/images/product_5.png" alt="" />
                      </div>
                      <div className="favorite"></div>
                      <div className="product_info">
                        <h6 className="product_name"><a href="#">Pryma Headphones, Rose Gold & Grey</a></h6>
                        <div className="product_price">$180.00</div>
                      </div>
                    </div>
                    <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                  </div>

                  <div className="product-item accessories">
                    <div className="product discount product_filter">
                      <div className="product_image">
                        <img src="assets/images/product_6.png" alt="" />
                      </div>
                      <div className="favorite favorite_left"></div>
                      <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center"><span>-$20</span></div>
                      <div className="product_info">
                        <h6 className="product_name"><a href="##">Fujifilm X100T 16 MP Digital Camera (Silver)</a></h6>
                        <div className="product_price">$520.00<span>$590.00</span></div>
                      </div>
                    </div>
                    <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                  </div>

                  <div className="product-item women">
                    <div className="product product_filter">
                      <div className="product_image">
                        <img src="assets/images/product_7.png" alt="" />
                      </div>
                      <div className="favorite"></div>
                      <div className="product_info">
                        <h6 className="product_name"><a href="#">Samsung CF591 Series Curved 27-Inch FHD Monitor</a></h6>
                        <div className="product_price">$610.00</div>
                      </div>
                    </div>
                    <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                  </div>

                  <div className="product-item accessories">
                    <div className="product product_filter">
                      <div className="product_image">
                        <img src="assets/images/product_8.png" alt="" />
                      </div>
                      <div className="favorite"></div>
                      <div className="product_info">
                        <h6 className="product_name"><a href="#">Blue Yeti USB Microphone Blackout Edition</a></h6>
                        <div className="product_price">$120.00</div>
                      </div>
                    </div>
                    <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                  </div>

                  <div className="product-item men">
                    <div className="product product_filter">
                      <div className="product_image">
                        <img src="assets/images/product_9.png" alt="" />
                      </div>
                      <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center"><span>sale</span></div>
                      <div className="favorite favorite_left"></div>
                      <div className="product_info">
                        <h6 className="product_name"><a href="#">DYMO LabelWriter 450 Turbo Thermal Label Printer</a></h6>
                        <div className="product_price">$410.00</div>
                      </div>
                    </div>
                    <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                  </div>

                  <div className="product-item men">
                    <div className="product product_filter">
                      <div className="product_image">
                        <img src="assets/images/product_10.png" alt="" />
                      </div>
                      <div className="favorite"></div>
                      <div className="product_info">
                        <h6 className="product_name"><a href="#">Pryma Headphones, Rose Gold & Grey</a></h6>
                        <div className="product_price">$180.00</div>
                      </div>
                    </div>
                    <div className="red_button add_to_cart_button"><a href="#">add to cart</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="deal_ofthe_week">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="deal_ofthe_week_img">
                  <img className="img-fluid" src="assets/images/premium_product.png" alt=""/>
                </div>
              </div>
              <div className="col-lg-6 text-right deal_ofthe_week_col">
                <div className="deal_ofthe_week_content d-flex flex-column align-items-center float-right">
                  <div className="section_title">
                    <h2>New Premium Products</h2>
                  </div>

                  <div className="red_button deal_ofthe_week_button"><a href="/products">view now</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="best_sellers">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <div className="section_title new_arrivals_title">
                  <h2>Best Sellers</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="product_slider_container">
                  <div className="owl-carousel owl-theme product_slider">
                    <BestSellers data={this.state.best_sellers}/>
                  </div>

                  <div className="product_slider_nav_left product_slider_nav d-flex align-items-center justify-content-center flex-column">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </div>
                  <div className="product_slider_nav_right product_slider_nav d-flex align-items-center justify-content-center flex-column">
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="benefit">
          <div className="container">
            <div className="row benefit_row">
              <div className="col-lg-3 benefit_col">
                <div className="benefit_item d-flex flex-row align-items-center">
                  <div className="benefit_icon"><i className="fa fa-truck" aria-hidden="true"></i></div>
                  <div className="benefit_content">
                    <h6>Shipping Charges Extra</h6>
                    <p>Suffered Alteration in Some Form</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 benefit_col">
                <div className="benefit_item d-flex flex-row align-items-center">
                  <div className="benefit_icon"><i className="fa fa-money" aria-hidden="true"></i></div>
                  <div className="benefit_content">
                    <h6>Pac</h6>
                    <p>The Internet Tend To Repeat</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 benefit_col">
                <div className="benefit_item d-flex flex-row align-items-center">
                  <div className="benefit_icon"><i className="fa fa-undo" aria-hidden="true"></i></div>
                  <div className="benefit_content">
                    <h6>45 days return</h6>
                    <p>Making it Look Like Readable</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 benefit_col">
                <div className="benefit_item d-flex flex-row align-items-center">
                  <div className="benefit_icon"><i className="fa fa-clock-o" aria-hidden="true"></i></div>
                  <div className="benefit_content">
                    <h6>Opening Mon-Sat</h6>
                    <p>8AM - 5PM</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="blogs">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <div className="section_title">
                  <h2>Latest Blogs</h2>
                </div>
              </div>
            </div>
            <div className="row blogs_container">
              <div className="col-lg-4 blog_item_col">
                <div className="blog_item">
                  <div className="blog_background" style={{backgroundImage:"url(assets/images/blog_1.jpg)"}}></div>
                  <div className="blog_content d-flex flex-column align-items-center justify-content-center text-center">
                    <h4 className="blog_title">Here are the trends I see coming this fall</h4>
                    <span className="blog_meta">by admin | dec 01, 2021</span>
                    <a className="blog_more" href="#">Read more</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 blog_item_col">
                <div className="blog_item">
                  <div className="blog_background" style={{backgroundImage:"url(assets/images/blog_2.jpg)"}}></div>
                  <div className="blog_content d-flex flex-column align-items-center justify-content-center text-center">
                    <h4 className="blog_title">Here are the trends I see coming this fall</h4>
                    <span className="blog_meta">by admin | dec 01, 2021</span>
                    <a className="blog_more" href="#">Read more</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 blog_item_col">
                <div className="blog_item">
                  <div className="blog_background" style={{backgroundImage:"url(assets/images/blog_3.jpg)"}}></div>
                  <div className="blog_content d-flex flex-column align-items-center justify-content-center text-center">
                    <h4 className="blog_title">Here are the trends I see coming this fall</h4>
                    <span className="blog_meta">by admin | dec 01, 2021</span>
                    <a className="blog_more" href="#">Read more</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
          <Footer />
      </div>
)
};
}

export default Home;
