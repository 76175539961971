
const Assets = {
    tablewares : "tablewares",
    platters : "platters",
    barwares : "barwares",
    copper_antique_finish: "copper_antique_finish",
    thali_set: "thali_set"

}


export default Assets;
