import Maps from "./Maps";

const footer = props => {

    return (

        <div>
        
            <footer id="footer">
                
                
                <div className="footer-widget">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="single-widget">
                                    <h2 className="text-center">About Company</h2>
                                    <ul className="nav nav-pills nav-stacked">
                                    <li><p>Established in 1992, New Atoll tableware is serving its customers for the last 30 years. 
                                        Manufacturing and Export of Hotelware, tableware, cutlery & other Hotel products. 
                                        Specialized in Stainless Steel and copper with premium Quality.</p></li>
                                    </ul>
                                </div>
                            </div>
                            
                            
                            <div className="col-sm-4">
                                <div className="single-widget">
                                    <h2 className="text-center">Contact Us</h2>
                                    <ul className="nav nav-pills nav-stacked">
                                    <li>
                                        <p style={{display:"flex"}}>
                                            <img className="icon-phone" src="/assets/images/icons/location.png"/>
                                            <label>16, Chitra Gupt Nagar, Shivpuri, Moradabad, Uttar Pradesh 244001</label>
                                        </p>
                                    </li>
                                    <li>
                                        <a href="tel:+91-9045002195">
                                            <img src="/assets/images/icons/phone.png" className="icon-phone footer-phone"/>
                                            <span>+91-9045002195</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:newatolltableware@gmail.com">
                                            <img style={{borderRadius: 40}} className="icon-phone" src="/assets/images/icons/mail.png"/>
                                            <span>newatolltableware@gmail.com</span>
                                        </a>
                                    </li>
                                    </ul>
                                </div>
                            </div>  
                            <div className="col-sm-4">
                                <div className="single-widget">
                                    <h2 className="text-center">Connect with us</h2>
                                    <ul className="nav nav-pills nav-stacked" style={{marginTop: 25}}>
                                    <li>
                                        <a href="https://www.facebook.com/NewAtollTableWare/">
                                            <img className="icon-big margin-left-100" src="/assets/images/icons/facebook.png"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://m.me/newatolltableware">
                                            <img className="icon-big margin-left-23" src="/assets/images/icons/messenger.png"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://api.whatsapp.com/send?phone=919045002195&text=Namaste">
                                            <img className="icon-big-whatsapp"  src="/assets/images/icons/whatsapp.png"/>
                                        </a>
                                    </li>
                                    </ul>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="single-widget text-center">
                                    <h2>Address</h2>
                                    <h6>16, Chitra Gupt Nagar, Shivpuri, Moradabad, Uttar Pradesh 244001
                                    </h6>
                                <br/>
                                <Maps/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="footer-bottom">
                    <div className="container">
                    <div className="row">
                        <p className="pull-left">Copyright © 2021 New Atoll Tablewares Inc. All rights reserved.</p>
                    </div>
                    </div>
                </div>
            
            </footer>
            
        </div>

    );

}
export default footer;