import React from 'react';
import CompanyInfo from '../constants/CompanyInfo'


class Header extends React.Component{

    componentDidMount = props => {
        document.getElementById(this.props.message).childNodes[0].classList.add('element-active');
    }
    componentWillUnmount = props => {
        document.getElementById(this.props.message).childNodes[0].classList.remove('element-active');
    }

    render(){
        
        return (
            
            <header className="header trans_300">
         
            <div className="top_nav">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="top_nav_left">Welcome to New Atol Tablewares</div>
                  </div>
                  <div className="col text-right">
                    <div className="top_nav_right">
                      <ul className="top_nav_menu">
                      
                        <li className="header-info">
                            <a className="d-none d-md-block" href="tel:+91-9045002195">
                              <span>Call us: </span>
                              <img src="/assets/images/icons/phone.png" className="icon-phone"/>
                              <span>+91-9045002195</span>
                            </a>
                            <a className="d-md-none" href="tel:+91-9045002195">
                              <img src="/assets/images/icons/phone.png" className="icon-phone"/>
                            </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
            <div className="main_nav_container">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="logo_container">
                      <a href="/"><img src={CompanyInfo.logo}/></a>
                    </div>



                    

                    {/*  */}
                    <nav className="navbar navbar-expand-lg  navbar-light">
                    <button className="navbar-toggler toggler-example" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="dark-blue-text">
                        <i className="fas fa-bars fa-1x"></i>
                      </span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent1">
                      
                    <ul className="navbar_menu navbar-nav mr-auto">
                        <li className="nav-item" id="menuHome"><a href="home">home</a></li>
                        <li className="nav-item" id="menuProducts" ><a href="products">products</a></li>
                        <li className="nav-item" id="menuAbout" ><a href="about">about</a></li>
                        {/* <li id="menuContact" ><a href="contact">contact</a></li> */}
                      </ul>
                    
                    </div>
                    
                      
                      
                      {/* <ul className="navbar_user">
                        <li>
                          <div className="header_area">
                            <div className="search-area">
                              <input type="search" name="search" id="headerSearch" placeholder="Search..." />
                              <button type="submit" value="Search"><i className="fa fa-search" aria-hidden="true"></i></button>

                            </div>
                          </div>
                        </li>
                        <li><a href="#"><i className="fa fa-user" aria-hidden="true"></i></a></li>
                        <li className="checkout">
                          <a href="#">
                            <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                            <span id="checkout_items" className="checkout_items">2</span>
                          </a>
                        </li>
                      </ul> */}
                      
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </header>  
        );
    }

}

export default Header;