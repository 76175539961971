import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/owl.carousel.css';
import './assets/css/owl.theme.default.css';
import './assets/css/animate.css';
import './assets/css/main_styles.css';
import './assets/css/responsive.css';
import './assets/css/all.min.css';
import './assets/css/maps.scss';
import './index.css';
import { createBrowserHistory } from 'history';
import { Router, Route} from 'react-router';
import Home from './components/Home';
import Products from './components/Products';
import About from './components/About';



const browserHistory = createBrowserHistory();


const routing = (  
  <Router history = {browserHistory}>
      <Route exact path = "/" component = {Home} />
        <Route exact path = "/home" component = {Home} />
        <Route exact path = "/products" component = {Products} />
         <Route path = "/about" component = {About} />
         {/* <Route path = "contact" component = {Contact} /> */}
   </Router>  
  
  
  // <div><Home /></div>
)  
ReactDOM.render(routing, document.getElementById('root'));  
