import React from 'react';


const BestSellers = props => {
    
    if(props.data != undefined){
        return (
            <>
                {
                    Object.entries(props.data).map(image => {
                        return (
                            <div key={image[0]} className="owl-item product_slider_item">
                                <div className="product-item">
                                    <div className="product">
                                    <div className="product_image banner_item">
                                        <img src={image[1]} alt="" />
                                    </div>
                                    {/* <div className="favorite"></div> */}
                                    <div className="product_info">
                                        {(image[0].substring(0, image[0].lastIndexOf('.')) || image[0]).replaceAll('_',' ')}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </>
        );
    }
    return (<div></div>)

}
export default BestSellers;