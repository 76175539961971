import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Assets from '../constants/Assets'

class About extends React.Component{

    

    render(){
        return (
            <div>
                <Header message="menuAbout"/>

                <section className="section-content padding-bottom-0 padding-y">


                    {/* Company Overview */}

                <div className="container margin-bottom-75">
                <div className="row">
                        <div className="col text-center">
                            <div className="section_title"><h2>Company Overview</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                        <p className="about-us-info margin-top-73">
                        NEW ATOLL TABLEWARE is the Manufacturer, Supplier, and Exporter of customized Hotelware, Tableware, Copperware, & Bar Accessories
A leading brand for manufacturing Hospitality & Catering products in Stainless Steel & Copper. We put customers at the core of our business, enabling us to offer our customers high-quality products that meet the latest market trends at a highly competitive price. 


                        </p>
                        </div>
                    </div>

                </div> 
                <div className="container">
                <div className="row">
                   
                <main className="col-md-12">
                    
                    <div className="row">

                        <div className="col-md-4">
                            <figure className="card card-product-grid">
                                <div className="img-wrap"> 
                                    <img src="/assets/images/company-overview1.png" style={{pointerEvents: 'none' }}  />
                                </div> 
                            </figure>
                        </div>
                        <div className="col-md-4">
                            <figure className="card card-product-grid">
                                <div className="img-wrap"> 
                                    <img src="/assets/images/company-overview2.png" style={{pointerEvents: 'none' }}  />
                                </div> 
                            </figure>
                        </div>
                        <div className="col-md-4">
                            <figure className="card card-product-grid">
                                <div className="img-wrap"> 
                                    <img src="/assets/images/company-overview3.png" style={{pointerEvents: 'none' }}  />
                                </div> 
                            </figure>
                        </div>
                        


                    
                    </div> 
                
                </main>
                </div>
                </div> 
                </section>
                
                <Footer />
               
             
            </div>
        );
    }
}


export default About;